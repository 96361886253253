<template>
  <b-card>
    <div class="d-flex justify-content-between flex-wrap my-1">
      <h4>{{ $t('form-create-item.price') }}</h4>
      <div class="d-flex">
        <b-button variant="primary" class="ml-1" @click="savePrices()">
          {{ $t('form.actions.save') }}
        </b-button>
      </div>
    </div>
    <!-- Show prices form -->
    <div>
      <b-form-group class="flex-grow-1">
        <label for="price" class="font-weight-bold">
           {{ $t("backoffice.settings.show") }} Precio
        </label>
        <v-select
          id="price"
          v-model="form.showPrice"
          :placeholder="$t('form.select-placeholder')"
          :options="priceOptions"
          :searchable="false"
          label="text"
          :reduce="(item)=> item.code"
          :clearable="false"
        />
      </b-form-group>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BFormGroup, BButton, BFormTextarea,
} from 'bootstrap-vue';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import vSelect from 'vue-select';

export default {
  name: 'PricesCard',
  components: {
    BCard,
    BFormGroup,
    BFormTextarea,
    BButton,
    vSelect,
  },
  mixins: [ToastNotificationsMixin],
  data() {
    return {
      form: {
        showPrice: true,
      },
      priceOptions: [{ text: this.$t('yes'), code: true }, { text: this.$t('no'), code: false }],
    };
  },
  computed: {
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
  },
  created() {
    this.form.showPrice = this.currentCollective?.showPrice;
  },
  methods: {
    async savePrices() {
      //Save prices data
      await this.$store.dispatch('editCommunity', {
        settings: {
          showPrice: this.form.showPrice,
        },
      });
    },
  },
};
</script>
